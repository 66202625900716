body {
  margin: 3rem;
  /* margin-left: 14rem; */
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 1.5;
}

@media (max-width: 768px) {
  body {
    margin-left: 0;
  }
}

.search {
  margin: 0 auto;
  width: 50%;
  display: flex;
  justify-content: space-between;
  justify-self: center;
  align-items: center;
}

.proformaTable th,
.proformaTable td {
  padding: 5px 10px;
}

.proformaTable {
  margin: 3rem auto;
}

.proformaTable th {
  background-color: #585858;
  border-bottom: 1px solid #ddd;
  color: #fff;
  font-weight: 400;
  text-align: center;
}

.buttons {
  display: flex;
  justify-self: start;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.addBankHistoryCard {
  display: none;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  z-index: 3;
}

.addBankHistoryCardForm {
  display: flex;
  flex-direction: column;
}

.closeBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.show {
  display: flex;
}

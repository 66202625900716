.grid {
  margin-top: 10vh;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: 130px 130px auto;
  grid-gap: 10px;
  border: 15px solid rgba(0, 11, 1, 0.028);
  padding: 12px;
  font-size: 20px;
  align-items: center;
}
.grid > div {
  /*  background-color: rgba(0, 11, 1, 0.028); */
  border: 1px solid rgba(0, 11, 1, 0.028);
  padding: 12px;
  font-size: 20px;
}

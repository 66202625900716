.errorMessage {
  display: flex;
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -330px;
  margin-left: -210px;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 8rem;
  width: 15rem;
  text-align: center;
  border-radius: 15px;
  -webkit-box-shadow: 10px 10px 15px -6px rgba(0, 0, 0, 0.25);
  transition: opacity 0.3s ease-in 0.3s, visibility 0.3s ease-in 0.3s;
  transform: scale(0.9);
  z-index: 1;
  background-color: antiquewhite;
}

.signUp {
  display: flex;
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -330px;
  margin-left: -210px;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 640px;
  width: 400px;
  text-align: center;
  border-radius: 15px;
  -webkit-box-shadow: 10px 10px 15px -6px rgba(0, 0, 0, 0.25);
  transition: opacity 0.3s ease-in 0.3s, visibility 0.3s ease-in 0.3s;
  transform: scale(0.9);
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.field {
  margin: 20px;
  text-align: left;
}

.input {
  margin: 5px;
  width: 300px;
  margin-left: -5px;
  height: 40px;
  border-radius: 2px;
  border: none;
  font-size: 18px;
  padding: 5px;
  color: #25291b;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 10px 10px 10px -10px rgba(0, 0, 0, 0.3);
  transition: background-color 0.4s ease-out;
}

.input:focus {
  outline: none;
  background-color: rgba(255, 255, 255, 0.8);
}

.label {
  margin-left: -5px;
  width: 310px;
}

.submit-button {
  cursor: pointer;
  margin-top: 20px;
  width: 310px;
  height: 50px;
  font-size: 20px;
  border: none;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  color: white;
  font-weight: bold;
  letter-spacing: 3px;

  transition: background-color 0.4s ease-out;
}

.submit-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.submit-button:focus {
  outline: none;
  background-color: rgba(0, 0, 0, 0.7);
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
  }
  40% {
    transform: scale(1.2);
  }
  70% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.01);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulse2 {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    transform: scale(1.4);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}


.allButtons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2rem;
}

.radioBtnPricePerUnitOrBox {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

@keyframes borderAnimation {
  0% {
    border-color: #3498db9e;
  }
  25% {
    border-color: #e74d3c9a;
  }
  50% {
    border-color: #2ecc708a;
  }
  75% {
    border-color: #f39d1274;
  }
  100% {
    border-color: #3498dbaf;
  }
}

.mainBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80vw;
  margin-left: auto;
  margin-top: 5.5rem;
}

.searchBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80vw;
  margin-left: auto;
  align-items: center;
}

.searchBox input {
  width: 50vw;
  margin-top: 1rem;
  padding: 0.5rem;
  border: 1px solid #00bfff;
  border-radius: 5px;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.searchBox input:focus {
  outline: none;
  border-color: #00bfff;
  box-shadow: 0 0 8px #00bfff;
  padding: 8px;
}

.searchBox input::placeholder {
  font-size: 1.5rem;
  color: #000951;
}

.clearButton {
  justify-content: center;
  margin-top: 0.2rem;
  color: #fff;
  font-weight: 400;
  text-align: center;
  border-radius: 10% 35%;
  background-color: #000724;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.9s ease-in-out, background-color 0.3s ease-in-out;
  font-size: 22px;
  margin-right: 0.6rem;
  padding: 0.1rem 0.4rem;
}

.clearButton:hover {
  border-radius: 35% 10%;
  color: #fff;
  background-color: #002b7a;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  scale: 0.95;
  transition: 0.9s;
  box-shadow: 8px 6px 2px rgba(0, 0, 0, 0.2);
  transform: skewX(0.5deg);
}

.addContact {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-around;
  width: 30vw;
  margin-top: 1rem;
  align-items: center;
}

.addContactsBtn {
  color: #fff;
  font-weight: 400;
  text-align: center;
  padding: 0.2rem 0.5rem;
  border: none;
  border-radius: 35% 10%;
  background-color: #000724;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.9s ease-in-out;
  font-size: 18px;
  margin-right: 0.6rem;
}

.addContactsBtn:hover {
  border-radius: 10% 35%;
  background-color: #001879;
  scale: 1.05;
  transition: 0.9s;
  box-shadow: 8px 6px 2px rgba(0, 0, 0, 0.2);
  transform: skewX(0.5deg);
}

.removeContactsBtn {
  color: #fff;
  font-weight: 400;
  text-align: center;
  padding: 0.1rem 0.5rem;
  border: none;
  border-radius: 10% 35%;
  background-color: #bb3800;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.9s ease-in-out;
  font-size: 18px;
}

.removeContactsBtn:hover {
  border-radius: 35% 10%;
  background-color: #fa2a00;
  scale: 1.05;
  transition: 0.9s;
  box-shadow: 8px 6px 2px rgba(0, 0, 0, 0.2);
  transform: skewX(0.5deg);
}

.ContactsViewTable {
  width: 80vw;
  margin-left: auto;
  margin-top: 4rem;
  border-collapse: collapse;
  border-radius: 15px;
}

.ContactsViewTable th {
  color: #000951;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 450;
  border-collapse: collapse;
}

.ContactsViewTable td {
  color: #000951;
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: 550 !important;
  border-collapse: collapse;
}

.ContactsViewButtonEdit {
  color: #fff;
  font-weight: 400;
  text-align: center;
  padding: 0.2rem 0.5rem;
  border: none;
  border-radius: 10% 35%;
  background-color: #000724;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.9s ease-in-out;
  font-size: 18px;
  margin-right: 0.6rem;
}

.ContactsViewButtonEdit:hover {
  border-radius: 35% 10%;
  scale: 1.05;
  transition: 0.9s;
  box-shadow: 8px 6px 2px rgba(0, 0, 0, 0.2);
  transform: skewX(0.5deg);
}

.ContactsViewButtonRemove {
  color: #fff;
  font-weight: 400;
  text-align: center;
  padding: 0.2rem 0.5rem;
  border: none;
  border-radius: 35% 10%;
  background-color: #fa2a00;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.9s ease-in-out;
  font-size: 18px;
}

@media screen and (max-width: 600px) {
  .mainBox {
    width: 150vw !important;
  }
  .searchBox {
    width: 150vw !important;
  }
  .addContact {
    width: 80vw;
    align-items: center;
  }
  .clearButton {
    justify-content: center;
    color: #fff;
    font-weight: 300;
    text-align: center;
    border-radius: 10% 35%;
    background-color: #000724;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.9s ease-in-out, background-color 0.3s ease-in-out;
    font-size: 22px;
    margin-right: 0.6rem;
    padding: 0.1rem 0.4rem;
  }
  .addContactsBtn {
    color: #fff;
    font-weight: 300;
    text-align: center;
    padding: 0.1rem 0.4rem;
    border: none;
    border-radius: 35% 10%;
    background-color: #000724;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.9s ease-in-out;
    font-size: 1rem;
    margin-right: 0.6rem;
  }
  .removeContactsBtn {
    color: #fff;
    font-weight: 300;
    text-align: center;
    padding: 0.1rem 0.4rem;
    border: none;
    border-radius: 10% 35%;
    background-color: #bb3800;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.9s ease-in-out;
    font-size: 1rem;
  }
  .ContactsViewTable {
    width: 150vw !important;
  }
}

.expandedRow {
  display: table;
  width: 100%;
  background-color: #ffffffe2;
}

.expandedRow div {
  padding: 0.5rem;
}

.expandedRow strong {
  display: table-header-group;
  font-size: 18px;
  color: #7a7a7a;
  /*   animation: borderAnimation 2s infinite; */
}

.expandedRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #000951;
  font-weight: 795;
  font-size: 1.3rem;
  color: #00041f;
}

.expandedRow .tableRow {
  display: table-row;
}

/* Footer container */
.footer {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0f0f0;
  border-top: 1px solid #ccc;
}

/* Left side styles */
.footer__left {
  flex: 1;
}

.footer__left label {
  font-weight: bold;
}

.footer__left input {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
}

/* Right side styles */
.footer__right {
  flex: 1;
}

.footer__right label {
  font-weight: bold;
}

.footer__right input,
.footer__right textarea {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
}

/* Center styles */
.footer__center {
  flex: 1;
}

.footer__center label {
  font-weight: bold;
}

.footer__center textarea {
  width: 100%;
  height: 100px;
  padding: 5px;
  border: 1px solid #ccc;
}

/* Additional styles */
.footer__left__title,
.footer__right__title,
.footer__center__title {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}

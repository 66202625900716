.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
  /* blur the background */
  backdrop-filter: blur(15px);
}

.secondTable {
  margin-top: 20px;
  width: 40%;
  border-collapse: collapse;
}

.closeModal {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
}

.overlay {
  /* center */
  position: fixed;
  top: 30;
  left: 50;
  transform: translate(0, 0);
  width: 50%;
  height: 50%;
  background-color: rgb(198, 231, 224);
  display: flex;
  align-items: center;
  justify-content: center;
  border: double 3px #760000;
  z-index: 1;
}

.backgroundOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* blur white */
  z-index: -1;
  background-color: rgba(64, 64, 64, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;
}

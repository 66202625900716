/* body {
  background-color: #222222;
  background: repeating-linear-gradient(
      45deg,
      #2b2b2b 0%,
      #2b2b2b 10%,
      #222222 0%,
      #222222 50%
    )
    0 / 15px 15px;
} */
/* 
.toggleContainer {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

/*Neon*/
  /*
.toggleContainer {
  text-align: center;
  font-size: 7em;
  margin-bottom: 0;
  margin-top: 0;
  line-height: 1;
  text-decoration: none;
  color: #fff;
}

.toggleContainer:nth-child(1) {
  font-family: Monoton;
  animation: neon1 1.5s ease-in-out infinite alternate;
}

.toggleContainer:nth-child(2) {
  font-family: Iceland;
  animation: neon2 1.5s ease-in-out infinite alternate;
}

.toggleContainer:nth-child(3) {
  font-family: Pacifico;
  animation: neon3 1.5s ease-in-out infinite alternate;
}

.toggleContainer:nth-child(4) {
  font-family: "Press Start 2P";
  animation: neon4 1.5s ease-in-out infinite alternate;
}

.toggleContainer:nth-child(5) {
  font-family: Audiowide;
  animation: neon5 1.5s ease-in-out infinite alternate;
}

.toggleContainer:nth-child(6) {
  font-family: Vampiro One;
  animation: neon6 1.5s ease-in-out infinite alternate;
}

/*glow*/
/*
@keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
      0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
      0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}

@keyframes neon2 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #228dff,
      0 0 70px #228dff, 0 0 80px #228dff, 0 0 100px #228dff, 0 0 150px #228dff;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #228dff,
      0 0 35px #228dff, 0 0 40px #228dff, 0 0 50px #228dff, 0 0 75px #228dff;
  }
}

@keyframes neon3 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ffdd1b,
      0 0 70px #ffdd1b, 0 0 80px #ffdd1b, 0 0 100px #ffdd1b, 0 0 150px #ffdd1b;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ffdd1b,
      0 0 35px #ffdd1b, 0 0 40px #ffdd1b, 0 0 50px #ffdd1b, 0 0 75px #ffdd1b;
  }
}

@keyframes neon4 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #b6ff00,
      0 0 70px #b6ff00, 0 0 80px #b6ff00, 0 0 100px #b6ff00, 0 0 150px #b6ff00;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #b6ff00,
      0 0 35px #b6ff00, 0 0 40px #b6ff00, 0 0 50px #b6ff00, 0 0 75px #b6ff00;
  }
}

@keyframes neon5 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff9900,
      0 0 70px #ff9900, 0 0 80px #ff9900, 0 0 100px #ff9900, 0 0 150px #ff9900;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff9900,
      0 0 35px #ff9900, 0 0 40px #ff9900, 0 0 50px #ff9900, 0 0 75px #ff9900;
  }
}

@keyframes neon6 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff00de,
      0 0 70px #ff00de, 0 0 80px #ff00de, 0 0 100px #ff00de, 0 0 150px #ff00de;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff00de,
      0 0 35px #ff00de, 0 0 40px #ff00de, 0 0 50px #ff00de, 0 0 75px #ff00de;
  }
}
/*Make stuff responsive*/
/*
@media (max-width: 650px) {
  p {
    font-size: 3.5em;
  }
}
 */



/* Corrected CSS */

.switchCheckbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switchLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 60px;
  height: 25px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.switchLabel .switchButton {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 22.5px;
  height: 20.5px;
  border-radius: 22.5px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switchCheckbox:checked + .switchLabel .switchButton {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}


.switchLabel:active .switchButton {
  width: 35px;
}


.switchButton::before {
  content: attr(data-content);
  position: absolute;
  top: -2px;
  left: 4px;
}

/* attr(data-content); is data-on color change off content to red*/

.switchCheckbox:checked + .switchLabel .switchButton::before {
  color: darkgreen;  /* color change for data-off */
}

.switchCheckbox:not(:checked) + .switchLabel .switchButton::before {
  color: #a10505; /* color change for data-on */
}


.switchLabel.colorOne {
  background: #85ff87b4;
}

.switchLabel.colorTwo {
  background: #ff8d8dc4;
}
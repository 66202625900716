.thStyle {
  width: 10%;
}
.thDiscriptionStyle {
  width: 15%;
}
.thTransportStyle {
  width: 15%;
}
/* CSS for ProformaInvoiceTable */
.proforma-invoice-table {
  padding: 10px;
  max-width: 100%;
}

/* CSS for Search and other elements */
.search-container {
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* CSS for the table */
.invoice-table {
  width: 100%;
  border-collapse: collapse;
}

.invoice-table th,
.invoice-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

/* CSS for the Add New Line button */
.add-line-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: blue;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  text-align: center;
}
/*******


Test CSS



*******/

.invoice-container {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.invoice-header {
  text-align: center;
}

.invoice-details {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.invoice-bill-to,
.invoice-ship-to {
  flex: 1;
}

.invoice-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.invoice-table th,
.invoice-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.invoice-total {
  text-align: right;
  margin-top: 20px;
}
.ActiveOrders {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  margin-left: 18rem;
}

.alert {
  padding: 20px;
  background-color: #f44336;
  color: white;
  margin-bottom: 15px;
  border-radius: 5px;
}

.alert.success {
  background-color: #4caf50;
}

.alert.info {
  background-color: #2196f3;
}

.alert.warning {
  background-color: #ff9800;
}

.butnsActions {
  position: fixed;
  top: 28vh;
  left: 55%;
  transform: translateX(-50%);
}

.butnsActionsNotSelected {
  position: fixed;
  top: 20vh;
  left: 50%;
  transform: translateX(-50%);
}

.hide {
  display: none;
}

.tdCheckbox {
  width: 1rem;
  height: 1rem;
  margin: 0;
}

.checkbox {
  width: 1.5rem;
  height: 1.5rem;
  margin: 15px;
}

.searchInput {
  width: 20rem;
  height: 2rem;
  margin-left: 50rem;
}

.dateInput{
  width: 20rem;
  height: 2rem;
  margin-left: 50rem;
}

.textUnderIcon {
  font-size: .8rem;
  font-weight: 300;
  margin-top: 0.5rem;
}
.totalDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5rem;
  margin-left: 8rem;
}

.totalDivItem {
  display: flex;
  flex-direction: row;
  margin-right: 5rem;
  padding: 1rem;
  border: thick double #325cce;
}

.totalDivItemTitle {
  font-size: 1.5rem;
  font-weight: 350;
  margin: 0;
  margin-right: 2rem;
}

.totalDivItemValue {
  font-size: 1.5rem;
  font-weight: 600;
  font-style: oblique;
  margin: 0;
}

.invoices {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  margin-left: 18rem;
}

.alert {
  padding: 20px;
  background-color: #f44336;
  color: white;
  margin-bottom: 15px;
  border-radius: 5px;
}

.alert.success {
  background-color: #4caf50;
}

.alert.info {
  background-color: #2196f3;
}

.alert.warning {
  background-color: #ff9800;
}

.butnsActions {
  position: fixed;
  top: 28vh;
  left: 55%;
  transform: translateX(-50%);
}

.butnsActionsNotSelected {
  position: fixed;
  top: 20vh;
  left: 50%;
  transform: translateX(-50%);
}

.hide {
  display: none;
}

.uploadExcelFile {
  width: 14rem;
}

.uploadExcelFile:hover {
  background-color: tomato;
  color: white;
}

.uploadExcelFile:active {
  background-color: tomato;
  color: white;
}

.uploadExcelFile:focus {
  background-color: tomato;
  color: white;
}

.uploadExcelFile:visited {
  background-color: tomato;
  color: white;
}

.tdCheckbox {
  width: 1rem;
  height: 1rem;
  margin: 0;
}

.checkbox {
  width: 1.5rem;
  height: 1.5rem;
  margin: 15px;
}

.searchInput {
  width: 20rem;
  height: 2rem;
  margin-left: 50rem;
}

.dateInput {
  width: 20rem;
  height: 2rem;
  margin-left: 50rem;
}

.textUnderIcon {
  font-size: 0.8rem;
  font-weight: 300;
  margin-top: 0.5rem;
}

.tableHead {
  text-align: center;
  vertical-align: middle;
  font-weight: 375;
  background-color: #a8a8a8 !important;
}

.tableData {
  text-align: center;
  vertical-align: middle;
  font-weight: 375;
  color: rgb(255, 94, 65) !important;
  background-color: rgba(255, 220, 214, 0.227) !important;
}

.paid {
  color: rgba(1, 63, 1, 0.786) !important;
  background-color: rgba(140, 212, 140, 0.433) !important;
  text-align: center;
  vertical-align: middle;
  font-weight: 375;
}

.popupPaymentDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
  position: fixed;
  top: 20vh;
  width: 75vw;
}

.popupPaymentDetailsId {
  color: rgb(64, 64, 64);
  background-color: #fff;
  width: fit-content;
  padding: 2px 24px;
  font-weight: 375;
  margin-right: 2rem;
}

/* .popupPaymentDetailsTble{
}

.popupPaymentDetailsTb{

} */

.paymentDateInputUnpaid {
  color: rgb(91, 0, 0);
  border: 1px solid rgba(255, 75, 75, 0.819);
  border-radius: 5px;
  background-color: #fff;
  box-shadow: inset 0 3px 6px rgba(255, 65, 65, 0.259);
  width: 9rem;
  height: 2rem;
  padding: 0.5rem;
}

.paymentDateInputpaid {
  color: rgb(1, 63, 1);
  border: 1px solid rgb(58, 173, 62);
  border-radius: 5px;
  background-color: #fff;
  box-shadow: inset 0 3px 6px rgba(84, 184, 84, 0.433);
  width: 9rem;
  height: 2rem;
  padding: 0.5rem;
}

/* Style labels for consistency */
label {
  font-weight: bold;
  margin-bottom: 5px;
}
.totalsUpdateModalAllDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.totalsUpdateModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
}

.totalAmountUpdateModal,
.unpaidUpdateModal {
  color: rgb(1, 63, 1);
  border: 1px solid rgb(58, 173, 62);
  border-radius: 5px;
  background-color: #fff;
  box-shadow: inset 0 3px 6px rgba(84, 184, 84, 0.433);
  width: fit-content;
  padding: 2px 24px;
  font-weight: 375;
}

.unpaidUpdateModal {
  color: rgb(91, 0, 0);
  border: 1px solid rgba(255, 75, 75, 0.819);
  border-radius: 5px;
  background-color: #fff;
  box-shadow: inset 0 3px 6px rgba(255, 65, 65, 0.259);
  width: fit-content;
  padding: 2px 24px;
  font-weight: 375;
}
.totalAmountInputUpdateModal {
  color: rgb(1, 63, 1);
  border: 1px solid rgb(58, 173, 62);
  border-radius: 5px;
  background-color: #fff;
  box-shadow: inset 0 3px 6px rgba(84, 184, 84, 0.433);
  width: 12rem;
  padding: 2px 24px;
  font-weight: 375;
  text-align: center;
}
.unpaidInputUpdateModal {
  color: rgb(91, 0, 0);
  border: 1px solid rgba(255, 75, 75, 0.819);
  border-radius: 5px;
  background-color: #fff;
  box-shadow: inset 0 3px 6px rgba(255, 65, 65, 0.259);
  width: 12rem;
  padding: 2px 24px;
  font-weight: 375;
  text-align: center;
  margin-left: 1rem;
}

.paymentUpdateTableHeader {
  text-align: center;
  vertical-align: middle;
  font-weight: 375;
  background-color: #6a6a6a;
  margin: 1.5rem;
}

.paymentDateInputUpdate {
  color: rgb(1, 63, 1);
  border: 1px solid rgb(58, 173, 62);
  border-radius: 5px;
  background-color: #fff;
  box-shadow: inset 0 3px 6px rgba(84, 184, 84, 0.433);
  width: 12rem;
  padding: 2px 12px;
  font-weight: 375;
}

.paymentDateLabelUpdate {
  /* put it ontop of the input field */
  background-color: #fff;
  padding: 0 8px;
}

.paymentMethodUpdate {
  color: rgb(1, 63, 1);
  border: 1px solid rgb(58, 173, 62);
  border-radius: 5px;
  background-color: #fff;
  box-shadow: inset 0 3px 6px rgba(84, 184, 84, 0.433);
  width: 12rem;
  padding: 2px 14px;
  font-weight: 375;
}

.paymentMethodUpdate:active {
  background-color: rgba(58, 173, 62, 0.17);
}

.paymentTotalPaidUpdate {
  color: rgb(1, 63, 1);
  border: 1px solid rgb(58, 173, 62);
  border-radius: 5px;
  background-color: #fff;
  box-shadow: inset 0 3px 6px rgba(84, 184, 84, 0.433);
  width: 12rem;
  padding: 2px 24px;
  font-weight: 375;
}

.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
  position: fixed;
  top: 20vh;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  /* blur the background */
  backdrop-filter: blur(15px);
}

.popupContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
  position: fixed;
  top: 20vh;
  width: 75vw;
  z-index: 100;
  /* blur the background */
  backdrop-filter: blur(15px);
}

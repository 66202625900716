/* Style for the dropdown container */

.material-symbols-outlined-Admin {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 12
  ;
  font-size: 1.5rem;
  color: #9a9a9a;
}

.dropdown {
  position: relative;
  display: inline-block;
}

/* Style for the dropdown button */
.dropbtn {
  background-color: #262626;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

/* Style for the dropdown content */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #474747;
  min-width: 160px;
  z-index: 1;
}

/* Style for individual dropdown items */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown items on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown content when the dropdown button is hovered */
.dropdown:hover .dropdown-content {
  display: block;
}


.navigation {
  background-color: #333;
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  transition: transform 0.3s ease-in-out;
  z-index: 999;
}

.nav-links {
  display: flex;
  flex-direction: column;
}

.nav-links a {
  color: white;
  margin: 10px 0;
  font-size: 18px;
  text-decoration: none;
}

.nav-links a:hover {
  text-decoration: underline;
  color: rgb(0, 92, 120);
}

nav a.active {
  color: #f00;
}

.toggle-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  color: white;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.body-container {
  margin-left: 200px; /* adjust this value based on the width of your navigation bar */
  transition: margin-left 0.3s ease-in-out;
}

@media screen and (max-width: 600px) {
  .navigation {
    width: 100%;
    height: auto;
    padding: 20px 0;
    transform: translateX(-100%);
  }

  .body-container {
    margin-left: 0;
  }

  .show-nav {
    transform: translateX(0);
  }

  .toggle-button {
    display: block;
  }
}

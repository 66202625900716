.mainDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
}

.bank{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80vw;
    margin-left: 25rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    flex-wrap: wrap;
    font-size: 24px;
    background-color: rgba(144, 144, 144, 0.577);
}

.bankCard {
  font-size: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 10rem;
  margin: 1rem;
  border-radius: 1rem;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.9s ease-in-out;
}

h4 {
  font-size: 1.5rem;
  margin: 0.5rem;
  text-align: center;
}

.bankCardOpen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 10rem;
  margin: 1rem;
  border-radius: 1rem;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.9s ease-in-out;
  transform: rotateY(180deg);
}

.bankCard:hover {
  scale: 1.1;
  transition: 0.5s;
  box-shadow: 12px 8px 5px rgba(0, 0, 0, 0.2);
  transform: skewX(0.5deg);
}

.bankSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80vw;
  margin-left: 25rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  font-size: 24px;
}

.bankTotalPerDayCard {
  display: flex;
}

.bankTotalStartPerDay{
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 10rem;
  height: 10rem;
  margin: 1rem;
  border-radius: 1rem;
  background-color: #696969;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.9s ease-in-out;
  margin-right: 8rem;
}

.bankTotalStartPerDay > p{
  color: #000264;
  font-size: 1.5rem;
  margin: 0.5rem;
  text-align: center;
  border: 1px solid rgb(255, 255, 255);
  background-color: #fff;
  border-radius: 1rem;
  padding: 0.5rem;
  width: 8rem;
  box-shadow: 5px 4px 2px rgba(0, 0, 0, 0.2);
}
.bankTotalEndDay{
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 10rem;
  height: 10rem;
  margin: 1rem;
  border-radius: 1rem;
  background-color: #696969;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.9s ease-in-out;
}

.bankTotalEndDay > p{
  color: #000264;
  font-size: 1.5rem;
  margin: 0.5rem;
  text-align: center;
  border: 1px solid rgb(255, 255, 255);
  background-color: #fff;
  border-radius: 1rem;
  padding: 0.5rem;
  width: 8rem;
  box-shadow: 5px 4px 2px rgba(0, 0, 0, 0.2);
}

.addBankHistoryBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15rem;
  border-radius: 1rem;
  color: #000724;
  background-color: #fff;
  box-shadow: 0 0 10px rgb(0, 0, 0);
  transition: transform 0.9s ease-in-out;
  margin: 1rem;
  margin-right: 1.5rem;
}

.addBankHistoryBtn:hover {
  scale: 1.05;
  transition: 1s;
  box-shadow: 8px 6px 2px rgba(0, 0, 0, 0.2);
  transform: skewX(0.5deg);

  /* inside color */
  color: #000724;
  background-color: #fff;
  border: 1px solid #000724;

}



.addBankHistoryCard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 15rem;
}

.addBankHistoryCardInput {
  width: 25rem;
  margin: 1rem;
}

.addBankHistoryCardInput > input {
  margin: 0.5rem;
  font-size: 20px;
  border: 1px solid rgb(0, 28, 4);
  border-radius: 8px;
  padding-left: 15px;
  padding-right: 15px;
}

.addBankHistoryCardInput > input:focus {
  outline: .1rem dashed rgb(0, 28, 4);
  border: none;
}

.addBankHistoryCardInput > select {
  cursor: pointer;
  margin: 0.5rem;
  font-size: 22px;
  border: 1px solid rgb(0, 28, 4);
  border-radius: 8px;
  padding-left: 15px;
  padding-right: 15px;
}

.addBankHistoryCardInput > select:focus {
  outline: .1rem dashed rgb(0, 28, 4);
  border: none;
}

.addBankHistoryCardInput > [type="date"] {
  cursor: pointer;
  margin: 0.5rem;
  font-size: 22px;
  border: 1px solid rgb(0, 28, 4);
  border-radius: 8px;
  padding-left: 15px;
  padding-right: 15px;
}

.modalBank {
  position: absolute;
  top: 8em;
  right: 6em;
  width: 80vw;
}

.modalBankBody {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 60vw;
  margin: 1rem;
  border-radius: 1rem;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.9s ease-in-out;
}

table {
  margin-top: 2rem;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  padding: 1rem;
  color: #00031f;
  margin-bottom: 2rem;
}

th {
  color: #000120;
  font-weight: 450;
  text-align: center;
  padding: 0.8rem;
  border-bottom: 1px solid #700404;
}

td {
  color: #1d1d1d;
  font-weight: 400;
  text-align: center;
  padding: 1rem;
}

td > button {
  color: #fff;
  font-weight: 400;
  text-align: center;
  padding: 0.4rem;
  border: none;
  border-radius: 35% 10%;
  background-color: #000724;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.9s ease-in-out;
  font-size: 18px;
}

td > button:hover {
  border-radius: 10% 35%;
  scale: 1.05;
  transition: 0.9s;
  box-shadow: 8px 6px 2px rgba(0, 0, 0, 0.2);
  transform: skewX(0.5deg);
}

.tableBankHead {
  color: #1d1d1d;
  font-weight: 450;
  text-align: center;
  padding: 0.8rem;
  border-bottom: 1px solid #1d1d1d;
}

.BankTable {
  color: #1d1d1d;
  font-weight: 400;
  text-align: center;
  padding: 1rem;
}

tr:hover td{
  background-color: #bdbdbd;
  color: #fff;
}
/* 
tr:hover td{
  background-color: #000120;
  color: #fff;
}

tr:hover td > button{
  background-color:#ffffff;
  color: #000724;
} */
.logout-container {
  text-align: center;
  margin-top: 50px;
}

.logout-container h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.logout-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

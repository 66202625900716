@media print {
  body * {
    display: none;
  }

  .print-x {
    display: none;
  }
  
  #print-content {
    display: block;
  }
}

.preview-x {
  display: none;
}
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80vw;
  margin-left: auto;
  margin-top: 3.5rem;
}

.main > h1 {
  font-size: 3.5rem;
  color: #000323;
  margin-bottom: 1rem;
  text-transform: capitalize;
  font-weight: 400;
}

/* if screan is big else */
@media (min-width: 1024px) {
  .inportContactsBtn {
      color: #fff;
      font-weight: 300;
      margin-right: auto;
      margin-left: 1rem;
      margin-top: 2.5rem;
      padding: 0.2rem 1rem;
      border: none;
      border-radius: 35% 10%;
      background-color: rgb(0, 22, 58);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      transition: 0.7s;
      font-size: 18px;
      cursor: pointer;
      align-self: center;
      transform: skewX(0.5deg);
      margin-bottom: 1rem;
    }
    
    .inportContactsBtn:hover {
      border-radius: 10% 35%;
      color: #fff;
      background-color: rgb(0, 22, 58);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        scale: 0.95;
        transition: 0.7s;
        box-shadow: 8px 6px 2px rgba(0, 0, 0, 0.2);
        transform: skewX(0.5deg);
    }

    .btwNumberContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      margin-top: 2.5rem;
      width: 20vw;
      align-items: flex-start;
    }

   #btwNumberLabel {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
      margin-left: auto;
      margin-right: auto;
    }
    
    .btwNumberContainer > label {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
      text-align: center;
      color: #000323;
    }
    
    .btwNumberContainer > input {
      width: 100%;
      padding: 0.5rem;
      font-size: 1.2rem;
      border-radius: 0.5rem;
      border: 1px solid #00bfff;
      margin-bottom: 0.8rem;
    }
    
    .btwNumberContainer > input:focus {
      outline: none;
      border-color: #00bfff;
      box-shadow: 0 0 8px #00bfff;
      padding: 8px;
    }
    
    .btwNumberContainer > button {
      color: #fff;
      font-weight: 300;
      text-align: center;
      padding: 0.2rem 1rem;
      border: none;
      border-radius: 10% 35%;
      background-color: rgb(0, 22, 58);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      transition: 0.7s;
      font-size: 18px;
      cursor: pointer;
      align-self: center;
      transform: skewX(0.5deg);
    }
    
    .btwNumberContainer > button:hover {
      border-radius: 35% 10%;
      color: #fff;
      background-color: rgb(0, 22, 58);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        scale: 0.95;
        transition: 0.7s;
        box-shadow: 8px 6px 2px rgba(0, 0, 0, 0.2);
        transform: skewX(0.5deg);
    }
} 

/* if screan is small else */
@media (max-width: 1024px) {
  .main > h1 {
    font-size: 2.5rem;
    color: #000323;
    margin-bottom: 1rem;
    text-transform: capitalize;
    font-weight: 400;
    text-align: center;
  }

  .inportContactsBtn {
      color: #fff;
      font-weight: 300;
      margin-right: auto;
      margin-top: 1.5rem;
      padding: 0.05rem 0.4rem;
      border: none;
      border-radius: 35% 10%;
      background-color: rgb(0, 22, 58);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      transition: 0.7s;
      font-size: 18px;
      cursor: pointer;
      align-self: center;
      transform: skewX(0.5deg);
      margin-bottom: 1rem;
    }
    
    .inportContactsBtn:hover {
      border-radius: 10% 35%;
      color: #fff;
      background-color: rgb(0, 22, 58);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        scale: 0.95;
        transition: 0.7s;
        box-shadow: 8px 6px 2px rgba(0, 0, 0, 0.2);
        transform: skewX(0.5deg);
    }

    .btwNumberContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin-top: 2.5rem;
      width: 70vw;
      align-items: flex-start;
    }

    #btwNumberLabel {
      font-size: 1rem;
      margin-bottom: 0.2rem;
      margin-left: auto;
      margin-right: auto;
    }

    .btwNumberContainer > label {
      font-size: 1rem;
      margin-bottom: 0.5rem;
      color: #000323;
    }

    .btwNumberContainer > input {
      width: 100%;
      padding: 0.2rem;
      font-size: 1.2rem;
      border-radius: 0.5rem;
      border: 1px solid #00bfff;
      margin-bottom: 0.8rem;
    }

    .btwNumberContainer > input:focus {
      outline: none;
      border-color: #00bfff;
      box-shadow: 0 0 8px #00bfff;
      padding: 8px;
    }

    .btwNumberContainer > button {
      color: #fff;
      font-weight: 300;
      text-align: center;
      padding: 0.1rem 0.6rem;
      border: none;
      border-radius: 10% 35%;
      background-color: rgb(0, 22, 58);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      transition: 0.7s;
      font-size: 18px;
      cursor: pointer;
      align-self: center;
      transform: skewX(0.5deg);
    }

    .btwNumberContainer > button:hover {
      border-radius: 35% 10%;
      color: #fff;
      background-color: rgb(0, 22, 58);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        scale: 0.95;
        transition: 0.7s;
        box-shadow: 8px 6px 2px rgba(0, 0, 0, 0.2);
        transform: skewX(0.5deg);
    }
}



.AddCustomerFieldsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;
  width: 70vw;
  align-items: flex-start;
  color: #000323;
}

.AddCustomerFieldsContainer > label {
  font-size: 1.5rem;
  font-weight: 450;
  margin-bottom: 0.2rem;
  color: #000323;
  margin-top: 1rem;
}

.AddCustomerFieldsContainer > input {
  width: 90%;
  padding: 0.5rem;
  font-size: 1.2rem;
  border-radius: 0.5rem;
  border: 1px solid #00bfff;
  margin-bottom: 0.5rem;
}

.AddCustomerFieldsContainer > input:focus {
  outline: none;
  border-color: #00bfff;
  box-shadow: 0 0 8px #00bfff;
  padding: 8px;
}

.invalidInput {
  border: 1px solid red !important;
}

.redAsterisk {
  color: red;
}

.deliveryAddressAddIcon {
  color: #000565;
  cursor: pointer;
  margin-top: 1.2rem;
  margin-bottom:  1.2rem;
}

.deliveryAddressAddHide {
  margin-left: 1rem;
  font-size: 1.2rem;
  font-weight: 450;
  color: #000565;
  cursor: pointer;
}

.deliveryAddressFields {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-bottom: 2.5rem;
  width: 40vw;
  align-items: flex-start;
  color: #000323;
}

.deliveryAddressFields > label {
  font-size: 1.5rem;
  font-weight: 450;
  margin-bottom: 0.2rem;
  color: #000323;
  margin-top: 1rem;
}

.deliveryAddressFields > input {
  width: 90%;
  padding: 0.4rem;
  font-size: 1.1rem;
  border-radius: 0.5rem;
  border: 1px solid #00bfff;
  margin-bottom: 0.4rem;
}

.deliveryAddressFields > input:focus {
  outline: none;
  border-color: #00bfff;
  box-shadow: 0 0 8px #00bfff;
  padding: 7px;
}

.formCheckLabel {
  margin-top: 1.2rem !important;
}

.AddCustomerFieldsContainer input[type="checkbox"] {
  width: 1.6rem !important;
  height: 1.6rem !important;
  background-color: #ffffff !important;
  transition: 0.2s !important;
  cursor: pointer !important;
  box-shadow: 0 0 8px #040c75 !important;
  margin-left: 15px !important;
}

.AddCustomerFieldsContainer input[type="checkbox"]:checked {
  background-color: #00bfff !important;
}

.formCheckInput {
  width: fit-content !important;
  margin-right: auto;
  margin-bottom: 2.5rem !important;
}

.btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  width: 60vw;
  align-items: flex-start;
  color: #000323;
}

.submitBtn {
  color: #fff;
  font-weight: 300;
  text-align: center;
  padding: 0.6rem 1.4rem;
  border: none;
  border-radius: 10% 35%;
  background-color: rgb(0, 22, 58);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: 0.7s;
  font-size: 18px;
  cursor: pointer;
  align-self: center;
  transform: skewX(0.5deg);
  margin-right: 1rem;
}

.submitBtn:hover {
  border-radius: 35% 10%;
    color: #fff;
    background-color:  #003eb3;
    scale: 0.95;
    transition: 0.7s;
    box-shadow: 8px 6px 2px rgba(0, 0, 0, 0.2);
    transform: skewX(0.5deg);
}

.resetBtn {
  color: #fff;
  font-weight: 300;
  text-align: center;
  padding: 0.6rem 1.4rem;
  border: none;
  border-radius: 35% 10%;
  background-color: rgb(181, 0, 0);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: 0.7s;
  font-size: 18px;
  cursor: pointer;
  align-self: center;
  transform: skewX(0.5deg);
}

.resetBtn:hover {
  border-radius: 10% 35%;
    color: #fff;
    background-color: rgb(255, 0, 0);
    scale: 0.95;
    transition: 0.7s;
    box-shadow: 8px 6px 2px rgba(0, 0, 0, 0.2);
    transform: skewX(0.5deg);
}

/* if small device */
@media (max-width: 1024px) {
  .resetBtn {
    color: #fff;
    font-weight: 300;
    text-align: center;
    padding: 0.2rem 0.5rem;
    border: none;
    border-radius: 35% 10%;
    background-color: rgb(181, 0, 0);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: 0.7s;
    font-size: 18px;
    cursor: pointer;
    align-self: center;
    transform: skewX(0.5deg);
  }

  .submitBtn {
    color: #fff;
    font-weight: 300;
    text-align: center;
    padding: 0.2rem 0.5rem;
    border: none;
    border-radius: 10% 35%;
    background-color: rgb(0, 22, 58);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: 0.7s;
    font-size: 18px;
    cursor: pointer;
    align-self: center;
    transform: skewX(0.5deg);
    margin-right: 1rem;
  }
}
.warning-style {
  animation: blink 1s infinite;
  color: #ffa500;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.mainBox {
  margin-top: 4rem;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #ccc;
  width: 60rem;
  margin-left: auto;
  margin-right: auto;
}

Form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
}

/* every 2nd a color */
/* Form label:nth-child(2n) {
  background-color: #0d00ff0e;
} */

/* and the other a color */
/* Form label:nth-child(2n + 1) {
  background-color: #00380b2e;
} */

Form label {
  font-weight: bold;
  align-items: flex-start;
}

Form input {
  margin-bottom: 1rem;
  padding: 1px;
  align-self: flex-end;
  margin-left: 1rem;
  border: 1px solid #ccc;
}

Form input[type="text"]:focus,
input[type="number"]:focus {
  width: 50%;
  border: 1px solid #033a98;
  outline: none;
}

Form input[type="textarea"]:focus {
  width: 50%;
  border: 1px solid #033a98;
  outline: none;
}

Form input[type="checkbox"] {
  margin-left: 0.5rem;
  border: 1px solid #ccc;
}

Form button {
  padding: 5px;
  width: 50%;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

Form button:hover {
  background-color: #0056b3;
}

Form h2 {
  margin-bottom: 20px;
}

.footerBox {
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  /* box-shadow: 0 0 5px #ccc; */
  width: 20rem;
  margin-left: auto;
  margin-right: auto;
}

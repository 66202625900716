.profile {
  /* move to the right */
  position: absolute;
  right: 0.5rem;
  top: 0;

  z-index: 1;

  /* make the name uppercase */
  text-transform: uppercase;
  font-style: italic;
}

.link {
  /* make the link look like a button */
  display: inline-block;
  padding: 0.2rem 0.5rem;
  margin: 0.3rem;
  border-radius: 0.5rem;
  text-decoration: none;
  color: white;
  background-color: #007bff;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease-in 0.3s;

  /* make the link look like a button when the user hovers over it */
  &:hover {
    background-color: #0056b3;
  }
}

.underline {
  text-decoration: underline;
}
.dropdown {
  display: inline-block;
}

.dropdown-content {
  display: none;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.show {
  display: block;
  position: absolute;
  right: 0;
  top: 100%;
}

.dropbtn {
  background-color: #4caf50;
  border-radius: 45%;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;

  text-transform: uppercase;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #3e8e41;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: 4rem;
  margin-left: 22rem;
  justify-content: center;
  margin-top: 8rem;
}

.CatalogTable {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 1rem;
  font-size: 0.975rem;
  line-height: 1.5;
  border-color: #ffffff;
  text-align: center;
  vertical-align: middle;
}

.CatalogTable th,
.CatalogTable td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.CatalogTable thead th {
  vertical-align: bottom;
}

.CatalogTable tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.CatalogTableHead {
  background-color: #404552;
  color: #ffffff;
  border-color: #ffffff;
}

.CatalogTableBody {
  background-color: #ffffff;
  color: #414141;
  border-color: #ffffff;
  vertical-align: middle;
  text-align: center;
  width: fit-content;
}

.CatalogTableBody tr:hover {
  background-color: #a5c1fc;
  color: #000426;
}

.CatalogTableBody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.CatalogTableBody tr:nth-child(even):hover {
  background-color: #a5c1fc;
  color: #000426;
}

.CatalogTableBody tr:nth-child(odd):hover {
  background-color: #a5c1fc;
  color: #000426;
}

.CatalogTableBody tr:nth-child(odd) {
  background-color: #ffffff;
}

.CatalogTableBody tr:last-child {
  background-color: #ffffff;
}

.CatalogTableBody tr:last-child:hover {
  background-color: #a5c1fc;
  color: #000426;
}

.butnsActions {
  position: fixed;
  top: 28vh;
  left: 55%;
  transform: translateX(-50%);
}

.butnsActionsNotSelected {
  position: fixed;
  top: 20vh;
  left: 68%;
}

.hide {
  display: none;
}

.textUnderIcon {
  font-size: 0.8rem;
  color: #fffefe;
}

/* type="checkbox" */
th > input[type="checkbox"],
td > input[type="checkbox"] {
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  margin: 0;
}

.productImage {
  width: 4.5rem;
  height: 3.5rem;
  border-radius: 30%;
}

.productImage:hover {
  cursor: pointer;
  border-radius: 5%;
}

.searchBar {
  /* centered */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  border: 1px solid #0f007d;
  border-radius: 5px;
  width: 25%;
  height: 3.5rem;
  margin-left: 50%;
  transform: translateX(-70%);
}

.searchBar input {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  padding-left: 1rem;
}

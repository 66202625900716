.box {
  margin-left: 14rem;
}

.container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.inputTypeTextPerformaInvoice {
  border: 1px solid #35363f;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 10px #ccc;
  border-color: #8ca6b9;
  margin: 0.2rem;
}

.inputTypeTextPerformaInvoice:focus {
  outline: none;
  border-color: #0077cc;
  box-shadow: 0 0 10px #0077cc;
}

.datePickerPerformaInvoice {
  margin: 5px;
}
.form-floating {
  position: relative;
}

.form-floating textarea {
  height: auto;
  padding-top: 1.625rem; /* Adjust the padding as needed */
}

.form-floating label {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  transition: 0.2s;
  transform-origin: top left;
}

.form-floating textarea.has-value + label,
.form-floating textarea:focus + label {
  transform: translate(0, -1rem) scale(0.85); /* Adjust the positioning and scaling as needed */
}

.form-floating textarea::placeholder {
  opacity: 1;
  transition: opacity 0.2s;
}

.form-floating textarea.has-value::placeholder {
  opacity: 0;
}

.selected-option .currency-symbol {
  font-size: 16px;
}
/* .container {
  display: flex;
  justify-content: space-between;
} */

.search-container {
  flex: 1;
}

.invoice-details {
  flex: 1;
}

.float-right {
  float: right;
  margin-left: 12.2rem; /* Adjust as needed */
}

/* .search-input {
  float: left;
} */
/*******

Test CSS


*******/

.invoice-container {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.invoice-header {
  text-align: center;
}

.invoice-details {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.invoice-bill-to,
.invoice-ship-to {
  flex: 1;
}

.invoice-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.invoice-table th,
.invoice-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.invoice-total {
  text-align: right;
  margin-top: 20px;
}

/* Add any additional styles as needed */
/* Hide everything by default for print media */
@media print {
  body * {
    display: none;
  }

  .new-invoice-container {
    display: block !important;
  }

  /* You can style .new-invoice-container and its children for printing here */
  /* Example styles: */

  .new-invoice-container {
    margin: 20px;
    padding: 10px;
  }

  /* Add more styles as needed */
}

@media print {
  html, body {
    height: 100vh; /* Use 100% here to support printing more than a single page*/
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
}
/* each li on one line */
.listOfProductsOnTheUnorderedList {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.listOfProducts {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.priceInputField {
  width: 150px;
  height: fit-content;
}

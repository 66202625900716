.tableMissingItems {
  border-collapse: collapse;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.tableMissingItems tr:hover {
  background-color: #aeaeae;
  transition: all 0.3s ease-in-out;
}

.tableheader {
  font-size: 0.9rem;
  font-weight: 650;
  text-align: center;
  color: #000653;
  background-color: #eceffd;
}

.rapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: nowrap;
  margin-top: 1rem;
}

.filterContainer {
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: 3rem;
  margin-right: 2.5rem;
  width: 20rem;
  box-shadow: 0 0 8px #040c75;
}

.filterContainer > ul,
.filterContainer > h2 {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #eceffd;
  border-radius: 5px;
  padding: 0.5rem;
}

.filterContainer > ul > li > input[type="checkbox"] {
  width: 1rem;
  height: 1rem;
  background-color: #ffffff;
  transition: 0.2s;
  cursor: pointer;
  box-shadow: 0 0 8px #52579d;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

.Header {
  background-color: #a8a8a8;
}

.missingItemsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
  margin-left: 20%;
  margin-right: auto;
  margin-top: 5rem;
}

.exportXlsxBtn {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.tdMissingItemsContent {
  padding: 10px;
  border: 1px solid #e0e0e0;
  color: #000322;
  font-size: 20px;
  font-weight: 450;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.missingItemsInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 1rem;
}

.textUnderIcon {
  font-size: 0.8rem;
  font-weight: 300;
  margin-top: 0.5rem;
}



.uploadExcelFile {
  width: 14rem;
}

.uploadExcelFile:hover {
  background-color: tomato;
  color: white;
}

.uploadExcelFile:active {
  background-color: tomato;
  color: white;
}

.uploadExcelFile:focus {
  background-color: tomato;
  color: white;
}

.uploadExcelFile:visited {
  background-color: tomato;
  color: white;
}

.previewTd{
  text-align: center;
  vertical-align: middle;
}

.previewTh{
  width: fit-content;
  padding: .4rem .9rem;
  text-align: center;
  vertical-align: middle;
  background-color: rgb(196, 205, 236);;
  color: rgb(49, 49, 49);
}

.previewTr:hover{
  background-color: rgb(99, 130, 242);
  color: rgb(250, 249, 249);
}

.previewTr{
  background-color: rgb(221, 226, 242);
  color: rgb(49, 49, 49);
}

.previewTotal{
  background-color: rgb(240, 240, 242);
  color: rgb(49, 49, 49);
}
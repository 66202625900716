.ConfirmACreatePackageListContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  background-color: rgb(209, 254, 255);
  padding: 2rem;
  border-radius: 45px;
}

.ConfirmACreatePackageListHeader {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.ConfirmACreatePackageListBtnPrdct {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}


.ConfirmACreatePackageListContainer >  button {
  /* btn styles */
  color: #fff;
  font-weight: 400;
  text-align: center;
  padding: 0.2rem 0.5rem;
  border: none;
  border-radius: 10% 35%;
  background-color: #000724;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.9s ease-in-out;
  font-size: 18px;
  margin-right: .6rem;
  text-transform: capitalize;
}

.ConfirmACreatePackageListContainer >  button:hover {
  scale: 1.05;
  background-color: #000951;
  border-radius: 35% 10%;
  transition: 0.9s;
  box-shadow: 8px 6px 2px rgba(0, 0, 0, 0.2);
  transform: skewX(0.5deg);
}
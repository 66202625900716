form {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin: 20px auto 0;
  max-width: 30rem;
  width: 16rem;
}

.inputTypeTextSearchBar {
  flex: 1;
  border: none;
  font-size: 16px;
}

.inputTypeTextSearchBar:focus {
  outline: none;
}

/* button[type="submit"] {
  background-color: #0077cc;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  margin-left: 10px;
  padding: 8px 16px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #0066b2;
} */

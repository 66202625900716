/* Button Styles */
.save-button {
  background-color: #007bff; /* Blue background color */
  color: #fff; /* White text color */
  border: none;
  padding: 10px 20px; /* Adjust padding as needed */
  margin-right: 10px; /* Add some spacing between the button and link */
  cursor: pointer;
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Adjust font size as needed */
}

.save-button:disabled {
  background-color: #ccc; /* Grayed out background color for disabled state */
  cursor: not-allowed;
}

/* Link Styles */
.save-link,
.cancel-link {
  text-decoration: none; /* Remove underlines from links */
  color: #007bff; /* Blue link color */
  font-weight: bold;
  transition: color 0.2s ease-in-out; /* Smooth color transition on hover */
}

.save-link:hover,
.cancel-link:hover {
  color: #0056b3; /* Darker blue color on hover */
}


/*
* 
#6a2c70
#AAABAB
#ffffff
#8d3a95
#471e4b
#c4c4c4

*/
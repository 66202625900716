/* Center the table and modal on the page */

/* Style the table */
table {
  border-collapse: collapse;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
/* 
table th,
table td {
  padding: 10px;
  text-align: left;
} */

/* table th {
  background-color: #858686;
  color: white;
} */

tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Style the Review button */
button {
  background-color: #636464;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

/* Style the modal */
.modal {
  display: block;
  z-index: 1;
  margin-left: 10%;
  background-color: #feffff;
  opacity: 1;
  position: fixed;
}

.modal-content {
  background-color: rgb(208, 208, 208);
  margin: 5rem auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(125, 125, 125, 0.2);
  width: 80%;
  position: relative;

}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #888;
}

.review-button {
  background-color: #636464;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  float: left;
}

.delete-button {
  background-color: #636464;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  float: right;
}
